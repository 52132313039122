// import reCAPTCHA from "react-google-recaptcha"
import React, { useRef } from 'react'

import '../styles/Home.css';

const HomePage = () => {

  React.useEffect(() => {
    const fonts = [
      "'Caveat', cursive",
      "'Jacques Francois Shadow', cursive",
      "'Qwitcher Grypen', cursive",
      "'Silkscreen', cursive",
      'Arial'
    ]
    let i = 0;
    const fontInterval = setInterval(() => {
      const button = document.getElementById('Submit').getElementsByTagName('button')[0]
      button.style.fontFamily = fonts[i]
      i = i + 1
      if (i >= fonts.length) {
        i = 0
      }
    }, 2000)
    return () => clearInterval(fontInterval);
  }, [])

  const captchaRef = useRef(null)
  // let navigate = useNavigate();

  const handleSubmit = (e) =>{
    e.preventDefault()
    // const token = captchaRef.current.getValue()
    // captchaRef.current.reset()
    // Show site
    const main = document.getElementById('main-content')
    main.style.visibility = 'visible';
    main.style.display = 'block';
    main.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <section id='Home'>
        <form id="Submit" onSubmit={handleSubmit} >
          <button>
            <div className="glitch" data-text="ImNotBot">ImNotBot</div>
            <reCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            theme={"dark"} />
          </button>
        </form>
      </section>
    </>
  )

}

export default HomePage;
