import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import '../styles/Footer.css';

function Footer() {
  const websiteName = "ImNotBot";
    return (
      <footer>
        <div className='footer-links'>
          <div className='link_list'>
            <h3>Content</h3>
            <Link to='/#Bots'>Bots</Link>
            <Link to='/#Mograph'>Mograph</Link>
            <Link to='/#Blockchain'>Blockchain</Link>
          </div>
          <div className='link_list'>
            <h3>Legal</h3>
            <Link to='/termsofuse'>Terms</Link>
            <Link to='/privacypolicy'>Privacy</Link>
            <Link to='/cookiepolicy'>Cookies</Link>
          </div>
        </div>
        <div>
          <p>© 2023 <b> {websiteName}</b> │ All Rights Reserved.</p>
        </div>
    </footer>
  );
}

export default Footer;
