import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Policy.css';
  
const CookiesPage = () => {
  const supportEmail = "imnotbot@duck.com";
  const websiteName = "ImNotBot";
  const domainName = "https://imnotbot.com";
  const websiteShortName = "imnotbot";
  const dateUpdated = "August 25, 2022";

  window.scrollTo(0, 0)
  document.title = 'ImNotBot - Cookie Policy'

  return (
    <main>
      <article id="cookiepolicy" className="policy-article">
        <h2>Cookie Policy</h2>
        <span>Last updated {dateUpdated}</span>
        <div className="text-container">
          <p>This Cookie Policy explains how {websiteName} ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at <Link to={domainName}>{domainName}</Link>, ("Websites"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
          <p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>
        </div>
        <div className="text-container">
          <h3>What are cookies?</h3>
          <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
          <p>Cookies set by the website owner (in this case, {websiteName}) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
        </div>
        <div className="text-container">
          <h3>Why do we use cookies?</h3>
          <p>We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below.</p>
          <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit).</p>
        </div>
        <div className="text-container">
          <h3>How can I control cookies?</h3>
          <p>Your browser or device will typically offer settings regarding the use and scope of cookies. Please see your browser’s or device’s reference information to learn how to adjust your settings. For example, you may be able to block all cookies, accept only first-party cookies, or delete all cookies upon exiting your browser. Please be aware that some services we offer will not work if you block or delete cookies.</p>
          <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">http://www.aboutads.info/choices</a> or <a href="http://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com</a>.</p>
          <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit).</p>
        </div>
        <div className="text-container">
          <h3>Essential website cookies:</h3>
          <p>These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</p>
          <table>
          <tbody>
            <tr>
              <th>Name:</th>
              <td>{websiteShortName}</td>
            </tr>
          <tr>
          <th>Purpose:</th>
          <td>Used to record user session</td>
          </tr>
          <tr>
          <th>Provider:</th>
          <td>{domainName}</td>
          </tr>
          <tr>
          <th>Service:</th>
          <td>{websiteName} <Link to="/privacypolicy">View Privacy Policy</Link></td>
          </tr>
          <tr>
          <th>Country:</th>
          <td>United States</td>
          </tr>
          <tr>
          <th>Type:</th>
          <td>http_cookie</td>
          </tr>
          <tr>
          <th>Expires:</th>
          <td>session</td>
          </tr>
          </tbody></table>
          <table>
          <tbody><tr>
          <th>Name:</th>
          <td>cookiepolicy</td>
          </tr>
          <tr>
          <th>Purpose:</th>
          <td>Used to record the cookie consent preferences of visitors</td>
          </tr>
          <tr>
          <th>Provider:</th>
          <td>{domainName}</td>
          </tr>
          <tr>
          <th>Service:</th>
          <td>{websiteName} <Link to="/privacypolicy">View Privacy Policy</Link></td>
          </tr>
          <tr>
          <th>Country:</th>
          <td>United States</td>
          </tr>
          <tr>
          <th>Type:</th>
          <td>http_cookie</td>
          </tr>
          <tr>
          <th>Expires:</th>
          <td>20 years</td>
          </tr>
          </tbody></table>
        </div>
        <div className="text-container">
        <h3>Analytics and customization cookies:</h3>
        <p>These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.</p>
        <table>
        <tbody><tr>
        <th>Name:</th>
        <td>_gid</td>
        </tr>
        <tr>
        <th>Purpose:</th>
        <td>Keeps an entry of unique ID which is then used to come up with statistical data on website usage by visitors. It is a HTTP cookie type and expires after a browsing session.</td>
        </tr>
        <tr>
        <th>Provider:</th>
        <td>{domainName}</td>
        </tr>
        <tr>
        <th>Service:</th>
        <td>Google Analytics <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">View Privacy Policy</a></td>
        </tr>
        <tr>
        <th>Country:</th>
        <td>United States</td>
        </tr>
        <tr>
        <th>Type:</th>
        <td>http_cookie</td>
        </tr>
        <tr>
        <th>Expires:</th>
        <td>1 day</td>
        </tr>
        </tbody></table>
        <table>
        <tbody><tr>
        <th>Name:</th>
        <td>_ga</td>
        </tr>
        <tr>
        <th>Purpose:</th>
        <td>It records a particular ID used to come up with data about website usage by the user. It is a HTTP cookie that expires after 2 years.</td>
        </tr>
        <tr>
        <th>Provider:</th>
        <td>{domainName}</td>
        </tr>
        <tr>
        <th>Service:</th>
        <td>Google Analytics <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">View Privacy Policy</a></td>
        </tr>
        <tr>
        <th>Country:</th>
        <td>United States</td>
        </tr>
        <tr>
        <th>Type:</th>
        <td>http_cookie</td>
        </tr>
        <tr>
        <th>Expires:</th>
        <td>1 year 12 months 4 days</td>
        </tr>
        </tbody></table>
        </div>
        <div className="text-container">
        <h3>Social networking cookies:</h3>
        <p>These cookies are used to enable you to share pages and content that you find interesting on our Websites through third party social networking and other websites. These cookies may also be used for advertising purposes.</p>
        <table>
        <tbody><tr>
        <th>Name:</th>
        <td>guest_id</td>
        </tr>
        <tr>
        <th>Purpose:</th>
        <td>Used on Twitter to help display tweets on the site, monitor referral links, used to identify those logged into Twitter and collect data related to the Twitter platform.</td>
        </tr>
        <tr>
        <th>Provider:</th>
        <td>.twitter.com</td>
        </tr>
        <tr>
        <th>Service:</th>
        <td>Twitter View Service Privacy Policy</td>
        </tr>
        <tr>
        <th>Country:</th>
        <td>United States</td>
        </tr>
        <tr>
        <th>Type:</th>
        <td>http_cookie</td>
        </tr>
        <tr>
        <th>Expires:</th>
        <td>1 year 12 months 4 days</td>
        </tr>
        </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>Name:</th>
              <td>personalization_id</td>
            </tr>
            <tr>
              <th>Purpose:</th>
              <td>Used on sites that share Twitter content and with Twitter share plugin. Persistent cookie that is set for 730 days</td>
            </tr>
            <tr>
              <th>Provider:</th>
              <td>.twitter.com</td>
            </tr>
            <tr>
              <th>Service:</th>
              <td>Twitter View Service Privacy Policy</td>
            </tr>
            <tr>
              <th>Country:</th>
              <td>United States</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>http_cookie</td>
            </tr>
            <tr>
              <th>Expires:</th>
              <td>1 year 12 months 4 days</td>
            </tr>
          </tbody>
        </table>
        <table>
            <tbody>
            <tr>
              <th>Name:</th>
              <td>_twitter_sess</td>
            </tr>
            <tr>
              <th>Purpose:</th>
              <td>Set by Twitter and lets website visitors use Twitter-related features from within the web page they are visiting.</td>
            </tr>
            <tr>
              <th>Provider:</th>
              <td>.twitter.com</td>
            </tr>
            <tr>
              <th>Service:</th>
              <td>Twitter View Service Privacy Policy</td>
            </tr>
            <tr>
              <th>Country:</th>
              <td>United States</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>http_cookie</td>
            </tr>
            <tr>
              <th>Expires:</th>
              <td>session</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>Name:</th>
              <td>ct0</td>
            </tr>
            <tr>
              <th>Purpose:</th>
              <td>Set to enable Twitter integration and sharing capabilities for the social media.</td>
            </tr>
            <tr>
              <th>Provider:</th>
              <td>.twitter.com</td>
            </tr>
            <tr>
              <th>Service:</th>
              <td>Twitter View Service Privacy Policy</td>
            </tr>
            <tr>
              <th>Country:</th>
              <td>United States</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>http_cookie</td>
            </tr>
            <tr>
              <th>Expires:</th>
              <td>6 hours</td>
            </tr>
          </tbody>
          </table>
        </div>

        <div className="text-container">
          <h3>Do you serve targeted advertising?</h3>
          <p>Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.</p>
        </div>
        <div className="text-container">
          <h3>How often will the Cookie Policy update?</h3>
          <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
          <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>
        </div>
        <div className="text-container">
          <h3>Where can I get further information?</h3>
          <p>If you have any questions about our use of cookies or other technologies, please email us at <a href={"mailto:"+supportEmail}>{supportEmail}</a>.</p>
        </div>
        </article>
      </main>
  );
}

export default CookiesPage;