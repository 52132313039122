import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import '../styles/Nav.css';

const Nav = () => {

    return (
    <>
        <nav>
            <h1><Link to='/'>ImNotBot</Link></h1>
        </nav>
    </>
    )
};

export default Nav;