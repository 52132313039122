import React from 'react';

import HomePage from './HomePage';
import Footer from './Footer';
import GasBanner from './GasBanner';
import '../styles/Dev.css';

const DevCard = ({type, title, description, link}) => {

  return <a key={title} className='card' href={link} target='_blank' rel='noreferrer'  alt={`View the ${title} git reposiroy on Github`}>
    <span>{type}</span>
    <div className='card-header'>
      <h3>{title}</h3>
      <div>
        <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">    <path d="M17.791,46.836C18.502,46.53,19,45.823,19,45v-5.4c0-0.197,0.016-0.402,0.041-0.61C19.027,38.994,19.014,38.997,19,39 c0,0-3,0-3.6,0c-1.5,0-2.8-0.6-3.4-1.8c-0.7-1.3-1-3.5-2.8-4.7C8.9,32.3,9.1,32,9.7,32c0.6,0.1,1.9,0.9,2.7,2c0.9,1.1,1.8,2,3.4,2 c2.487,0,3.82-0.125,4.622-0.555C21.356,34.056,22.649,33,24,33v-0.025c-5.668-0.182-9.289-2.066-10.975-4.975 c-3.665,0.042-6.856,0.405-8.677,0.707c-0.058-0.327-0.108-0.656-0.151-0.987c1.797-0.296,4.843-0.647,8.345-0.714 c-0.112-0.276-0.209-0.559-0.291-0.849c-3.511-0.178-6.541-0.039-8.187,0.097c-0.02-0.332-0.047-0.663-0.051-0.999 c1.649-0.135,4.597-0.27,8.018-0.111c-0.079-0.5-0.13-1.011-0.13-1.543c0-1.7,0.6-3.5,1.7-5c-0.5-1.7-1.2-5.3,0.2-6.6 c2.7,0,4.6,1.3,5.5,2.1C21,13.4,22.9,13,25,13s4,0.4,5.6,1.1c0.9-0.8,2.8-2.1,5.5-2.1c1.5,1.4,0.7,5,0.2,6.6c1.1,1.5,1.7,3.2,1.6,5 c0,0.484-0.045,0.951-0.11,1.409c3.499-0.172,6.527-0.034,8.204,0.102c-0.002,0.337-0.033,0.666-0.051,0.999 c-1.671-0.138-4.775-0.28-8.359-0.089c-0.089,0.336-0.197,0.663-0.325,0.98c3.546,0.046,6.665,0.389,8.548,0.689 c-0.043,0.332-0.093,0.661-0.151,0.987c-1.912-0.306-5.171-0.664-8.879-0.682C35.112,30.873,31.557,32.75,26,32.969V33 c2.6,0,5,3.9,5,6.6V45c0,0.823,0.498,1.53,1.209,1.836C41.37,43.804,48,35.164,48,25C48,12.318,37.683,2,25,2S2,12.318,2,25 C2,35.164,8.63,43.804,17.791,46.836z"/></svg>
      </div>
    </div>
    <p>{description}</p>
  </a>

}

const WorkPage = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <HomePage />
    <main id='main-content'>
      <GasBanner />
      <section id='Bots'>
        <div className='dev-container'>
          <h2>Discord Bots</h2>
          <div className='projects'>
              <DevCard type={'Discord Bot'} title={'Announcer TTS'} description={'Get a bot to announce users in voice chat with text to speech.'} link={'https://github.com/LatentFreedom/discord-announcer-tts'}/>
              <DevCard type={'Discord Bot'} title={'Gas Tracker'} description={"Get a Bot to change it's activity status to the current gas prices for Ethereum."} link={'https://github.com/LatentFreedom/discord-gastracker'}/>
              <DevCard type={'Discord Bot'} title={'ETH Tracker'} description={"Get a Bot to change it's activity status to the current price of Ethereum."} link={'https://github.com/LatentFreedom/discord-ethtracker'}/>
              <DevCard type={'Discord Bot'} title={'OpenSea Tracker'} description={"Get a bot to check opensea collection data in discord."} link={'https://github.com/LatentFreedom/discord-opensea'}/>
              <DevCard type={'Discord Bot'} title={'Gather Wallets'} description={"Get a bot to gather wallets in discord."} link={'https://github.com/LatentFreedom/discord-gatherwallets'}/>
              <DevCard type={'Discord Bot'} title={'Gather Wallets AirTable'} description={"Get a bot to gather wallets in discord and utilize airtables."} link={'https://github.com/LatentFreedom/discord-gatherwallets-airtable'}/>
              <DevCard type={'Discord Bot'} title={'Project Info'} description={"Get a bot to explain what the discord is about with helpful commands."} link={'https://github.com/LatentFreedom/discord-projectinfo'}/>
              <DevCard type={'Discord Bot'} title={'OpenAI'} description={"Get a bot to use chatgpt and other openai API endpoints in discord."} link={'https://github.com/LatentFreedom/discord-openai'}/>
          </div>
        </div>
      </section>
      <section id='Mograph'>
        <div className='mograph-container'>
          <h2>Mograph</h2>
          <div className='infinite-scroll'>
            <div className='fade-away'></div>
            <div className='fade-in'></div>
            <div className='mograph'>
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsnft-3RghN6rbF1l7amCX70' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeTQ5NGx3MHJ6bnNnMXlpajU5aWNtNmNiY2ZlaHd2am9xeWNuOWFyMCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3RghN6rbF1l7amCX70/giphy.gif' alt={'sneaky bat #4981 nft card gif'}></img></a>
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsnft-yKV4giNDtEof7BZXlb' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbzRyOGQ5dG93OW91cjVzMm1tMmxncGw5b2VxNm9rbG5pNTZpOXdibyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/yKV4giNDtEof7BZXlb/giphy-downsized-large.gif' alt={'sneaky vampire showcase'}></img></a>
                <a href='https://giphy.com/gifs/sneaky-vampire-syndicate-vampiress-WbbbIM0keNtkrLmGEm' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnZlcmIzYjBzcHYzMjd3eG15MHk2eG5ocHR3bjdqaXR0Y280ZXBkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/WbbbIM0keNtkrLmGEm/giphy-downsized-large.gif' alt={'sneaky vampiress nft cards gif'}></img></a>
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsvamp-KM3sNqXReLgbqVdn07' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMDQ4bmQ2ZzUwd2V1M3BucnU2Y2J3cngzajhuOGg0dDY4bW96dGtscCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/KM3sNqXReLgbqVdn07/giphy-downsized-large.gif' alt={'sneaky vampire financier gif'}></img></a>
                
                <a href='https://tenor.com/view/svs-svsnft-sneaky-sneaky-vampire-syndicate-cheers-gif-26845049' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/eb1f44e3241e431bdbd894ed4829402c/tenor.gif?itemid=26845049' alt={'sneaky vampire #4981 cheers gif'}></img></a>
                <a href='https://tenor.com/view/svs-svsnft-sneaky-vampire-vampire-sneaky-vampire-syndicate-gif-26522030' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/a001f00d95ee81f537286373bb5e64e3/tenor.gif?itemid=26522030' alt={'sneaky vampire #4897 burning vampire champagne gif'}></img></a>
                <a href='https://tenor.com/view/svs-svsnft-svsgm-gm-good-morning-gif-27177436' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/69a4eb4ac0b9d3c62e44585a2a06ad84/tenor.gif?itemid=27177436' alt={'sneaky vampire #3536 gm gif'}></img></a>
                <a href='https://tenor.com/view/pixel-vampires-svsnft-svs-sneaky-vampire-syndicate-nft-gif-27180910' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/86d617c583ab15bec0be49455878355a/tenor.gif?itemid=27180910' alt={'sneaky pixel syndicate promo gif'}></img></a>
                <a href='https://tenor.com/view/pixel-vampire-syndicate-svs-svsnft-nft-vampire-gif-27180922' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/f7f962cb8bea2ed037306788718d0fa9/tenor.gif?itemid=27180922' alt={'sneaky vampire #4981 pixel promo gif'}></img></a>
                <a href='https://tenor.com/view/svsnft-svs-sneaky-vampire-syndicate-vampire-vampire-christmas-gif-27209977' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/b91786c00ca33177406929090daeac22/tenor.gif?itemid=27209977' alt={'sneaky vampire # holiday gif'}></img></a>
                
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsnft-3RghN6rbF1l7amCX70' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeTQ5NGx3MHJ6bnNnMXlpajU5aWNtNmNiY2ZlaHd2am9xeWNuOWFyMCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3RghN6rbF1l7amCX70/giphy.gif' alt={'sneaky bat #4981 nft card gif'}></img></a>
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsnft-yKV4giNDtEof7BZXlb' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbzRyOGQ5dG93OW91cjVzMm1tMmxncGw5b2VxNm9rbG5pNTZpOXdibyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/yKV4giNDtEof7BZXlb/giphy-downsized-large.gif' alt={'sneaky vampire showcase'}></img></a>
                <a href='https://giphy.com/gifs/sneaky-vampire-syndicate-vampiress-WbbbIM0keNtkrLmGEm' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNnZlcmIzYjBzcHYzMjd3eG15MHk2eG5ocHR3bjdqaXR0Y280ZXBkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/WbbbIM0keNtkrLmGEm/giphy-downsized-large.gif' alt={'sneaky vampiress nft cards gif'}></img></a>
                <a href='https://giphy.com/gifs/svs-sneaky-vampire-syndicate-svsvamp-KM3sNqXReLgbqVdn07' target='_blank' rel='noreferrer' alt='View this gif on giphy'><img src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMDQ4bmQ2ZzUwd2V1M3BucnU2Y2J3cngzajhuOGg0dDY4bW96dGtscCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/KM3sNqXReLgbqVdn07/giphy-downsized-large.gif' alt={'sneaky vampire financier gif'}></img></a>
                
                <a href='https://tenor.com/view/svs-svsnft-sneaky-sneaky-vampire-syndicate-cheers-gif-26845049' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/eb1f44e3241e431bdbd894ed4829402c/tenor.gif?itemid=26845049' alt={'sneaky vampire #4981 cheers gif'}></img></a>
                <a href='https://tenor.com/view/svs-svsnft-sneaky-vampire-vampire-sneaky-vampire-syndicate-gif-26522030' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/a001f00d95ee81f537286373bb5e64e3/tenor.gif?itemid=26522030' alt={'sneaky vampire #4897 burning vampire champagne gif'}></img></a>
                <a href='https://tenor.com/view/svs-svsnft-svsgm-gm-good-morning-gif-27177436' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/69a4eb4ac0b9d3c62e44585a2a06ad84/tenor.gif?itemid=27177436' alt={'sneaky vampire #3536 gm gif'}></img></a>
                <a href='https://tenor.com/view/pixel-vampires-svsnft-svs-sneaky-vampire-syndicate-nft-gif-27180910' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/86d617c583ab15bec0be49455878355a/tenor.gif?itemid=27180910' alt={'sneaky pixel syndicate promo gif'}></img></a>
                <a href='https://tenor.com/view/pixel-vampire-syndicate-svs-svsnft-nft-vampire-gif-27180922' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/f7f962cb8bea2ed037306788718d0fa9/tenor.gif?itemid=27180922' alt={'sneaky vampire #4981 pixel promo gif'}></img></a>
                <a href='https://tenor.com/view/svsnft-svs-sneaky-vampire-syndicate-vampire-vampire-christmas-gif-27209977' target='_blank' rel='noreferrer' alt='View this gif on tenor'><img src='https://media1.tenor.com/images/b91786c00ca33177406929090daeac22/tenor.gif?itemid=27209977' alt={'sneaky vampire # holiday gif'}></img></a>
            </div>
          </div>
        </div>
      </section>
      <section id='Blockchain'>
        <div className='lair-container'>
          <h2>Blockchain</h2>
          <article className='hodl-data'>
            <div className='flex-container'>
                <div className='text-container'>
                  <h3>HODLs</h3>
                  <p>Wallet preview page using the Alchemy API to showcase some of the long term HODLs.</p>
                </div>
                <a className='content-container' href='https://lair.imnotbot.com/hodls' aria-label='View ImNotBot SVS HODLs.'>
                  <div className='imgs'>
                    <img src='images/hodl.png' alt='ImNotBot SVS HODL screenshot 1' className='background'></img>
                    <img src='images/hodl2.png' alt='ImNotBot SVS HODL screenshot 2' className='background'></img>
                    <img src='images/hodl3.png' alt='ImNotBot SVS HODL screenshot 3' className='foreground'></img>
                  </div>
                </a>
            </div>
          </article>
          <article className='lair-data'>
            <div className='flex-container'>
                <div className='text-container'>
                  <h3>Lair Data</h3>
                  <p>Daily data scraped using the Alchemy API to analyze the <b>SVS</b> collection.</p>
                </div>
                <a className='content-container' href='https://lair.imnotbot.com/data' aria-label='View the SVS lair data scraped by ImNotBot.'>
                  <div className='imgs'>
                    <img src='images/lair.png' alt='Scraped SVS data table screenshot 1' className='background'></img>
                    <img src='images/lair2.png' alt='Scraped SVS data table screenshot 2' className='background'></img>
                    <img src='images/lair3.png' alt='Scraped SVS data table screenshot 3' className='foreground'></img>
                  </div>
                </a>
            </div>
          </article>
          <article className='lookup-data'>
            <div className='flex-container'>
              <div className='text-container'>
                  <h3>Vamps</h3>
                  <p>Lookup the different attributes for vampires & vampiresses in the <b>SVS</b> collections using the Alchemy API.</p>
                </div>
                <a className='content-container' href='https://lair.imnotbot.com/vamps' aria-label='View the vamp lookup web app.'>
                  <div className='imgs'>
                    <img src='images/lookup.png' alt='SVS vamp lookup screenshot 1' className='background'></img>
                    <img src='images/lookup.png' alt='SVS vamp lookup screenshot 2' className='background'></img>
                    <img src='images/lookup2.png' alt='SVS vamp lookup screenshot 3' className='foreground'></img>
                  </div>
                </a>
            </div>
          </article>
          <article className='game-data'>
            <div className='flex-container'>
              <div className='text-container'>
                <h3>Game</h3>
                <p>Blockchain coinflip game using the <b>SVS</b> utility token <a href='https://polygonscan.com/token/0x9c8919d6e97be2b8b823fffb699ef1cb3422c77f' alt='Check out SVS PBLOOD token tracker on polygonscan' target='_blank' rel='noopener noreferrer'>$PBLOOD</a> and <a href='https://chain.link/vrf' alt='Read more about Chainlink VRF' target='_blank' rel='noopener noreferrer'>Chainlink VRF</a>.</p>
              </div>
              <a className='content-container' href='https://lair.imnotbot.com/games' aria-label='View the SVS coinflip game developed by ImNotBot.'>
                  <div className='imgs'>
                    <img src='images/game.png' alt='SVS coinflip game screenshot 1' className='background'></img>
                    <img src='images/game2.png' alt='SVS coinflip game screenshot 2' className='background'></img>
                    <img src='images/game3.png' alt='SVS coinflip game screenshot 3' className='foreground'></img>
                  </div>
              </a>
            </div>
          </article>
        </div>
      </section>
      <Footer />
    </main>
    </>
  )

}

export default WorkPage;
