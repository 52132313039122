import {Routes, Route } from 'react-router-dom';

import '../styles/App.css';
import Nav from './Nav';
import WorkPage from './WorkPage';
import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';
import CookiesPage from './CookiesPage';
import Error404Page from './404Page.js';
import Footer from './Footer';

function App() {

  return (
    <>
      <Routes>
          <Route path='/' element={<WorkPage />} />
          <Route path='/*' element={<ContentPage />} />
      </Routes>
    </>
  );
}

function ContentPage() {

  return (
    <>
      <Nav />
      <Routes>
          <Route path='/termsofuse' element={<TermsPage />} />
          <Route path='/privacypolicy' element={<PrivacyPage />} />
          <Route path='/cookiepolicy' element={<CookiesPage />} />
          <Route path='*' element={<Error404Page />} />
      </Routes>
      <Footer />

    </>
  );

}

export default App;
